<!-- 对账页面 -->
<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="payout-container">
    <div class="title-container">
      <div>流水列表</div>
      <el-button class="common-screen-btn" @click="() => download()">导出</el-button>
    </div>
    
    <!-- 头部数据统计 -->
    <div v-loading="loading2" element-loading-text="数据较多，拼命加载中..." class="common-Summary-container">
      <div style="display:flex;flex-direction: column;width: 100%">
        <div style="display:flex;align-items: center;">
          <span class="income">支出</span>
          <div class="Summary-item no-width">
            <div class="item-title">当日支出</div>
            <div
              @click="
                () => {
                  collect(
                    'payment-todayAmount',
                    '当日支出',
                    [],
                    [
                      { name: '流水编号', colProp: ['code'] },
                      { name: '出账金额', colProp: ['amount'] },
                      { name: '出账状态', colProp: ['status'] },
                      { name: '出账类型', colProp: ['expenditureType'] },
                      { name: '收款人', colProp: ['transactionName'] },
                      { name: '出账方式', colProp: ['recordType'] },
                      { name: '出账账户', colProp: ['transactionAccount'] },
                      { name: '备注', colProp: ['remark'] }
                    ],
                    'payout'
                  );
                }
              "
              class="item-num redFt"
            >
              {{ parseFloat(checktitle.todayAmount).toFixed(2) || "0" }}
            </div>
          </div>

          <div class="Summary-item no-width">
            <div class="item-title">昨日支出</div>
            <div
              @click="
                () => {
                  collect(
                    'payment-yesterdayAmount',
                    '昨日支出',
                    [],
                    [
                      { name: '流水编号', colProp: ['code'] },
                      { name: '出账金额', colProp: ['amount'] },
                      { name: '出账状态', colProp: ['status'] },
                      { name: '出账类型', colProp: ['expenditureType'] },
                      { name: '收款人', colProp: ['transactionName'] },
                      { name: '出账方式', colProp: ['recordType'] },
                      { name: '出账账户', colProp: ['transactionAccount'] },
                      { name: '备注', colProp: ['remark'] }
                    ],
                    'payout'
                  );
                }
              "
              class="item-num redFt"
            >
              {{ parseFloat(checktitle.yesterdayAmount).toFixed(2) || "0" }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <div class="item-title">当月支出</div>
            <div
              @click="
                () => {
                  collect(
                    'payment-monthAmount',
                    '当月支出',
                    [],
                    [
                      { name: '流水编号', colProp: ['code'] },
                      { name: '出账金额', colProp: ['amount'] },
                      { name: '出账状态', colProp: ['status'] },
                      { name: '出账类型', colProp: ['expenditureType'] },
                      { name: '收款人', colProp: ['transactionName'] },
                      { name: '出账方式', colProp: ['recordType'] },
                      { name: '出账账户', colProp: ['transactionAccount'] },
                      { name: '备注', colProp: ['remark'] }
                    ],
                    'payout'
                  );
                }
              "
              class="item-num redFt"
            >
              {{ parseFloat(checktitle.monthAmount).toFixed(2) || "0" }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <div class="item-title">当年支出</div>
            <div
              @click="
                () => {
                  collect(
                    'payment-yearAmount',
                    '当年支出',
                    [],
                    [
                      { name: '流水编号', colProp: ['code'] },
                      { name: '出账金额', colProp: ['amount'] },
                      { name: '出账状态', colProp: ['status'] },
                      { name: '出账类型', colProp: ['expenditureType'] },
                      { name: '收款人', colProp: ['transactionName'] },
                      { name: '出账方式', colProp: ['recordType'] },
                      { name: '出账账户', colProp: ['transactionAccount'] },
                      { name: '备注', colProp: ['remark'] }
                    ],
                    'payout'
                  );
                }
              "
              class="item-num redFt"
            >
              {{ parseFloat(checktitle.yearAmount).toFixed(2) || "0" }}
            </div>
          </div>
        </div>
        <div :class="{'isMore-height': isMore,'isMore-height0': !isMore}">
          <div style="display:flex;align-items: center;">
          <span class="income">总收入</span>
          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="应收总金额=订单应收金额+接单应收金额"
          placement="bottom"
          enterable
        >
            <div class="item-title">应收总金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
            </el-tooltip>
            <div
              @click="
                () => {
                  collect(
                    'totalPriceAll',
                    '应收总金额',
                    [
                      { name: '订单编号/名称', type: 'input', modelKey: 'orderNoAndName' },
                      { name: '客户名称', type: 'input', modelKey: 'customerName' },
                      { name: '创建人', type: 'input', modelKey: 'createName' },
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '应收金额', colProp: ['totalPriceAll'] },
                      { name: '创建人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ],
                    '',
                    'dis'
                  );
                }
              "
              class="item-num"
            >
              {{ parseFloat(checktitle.totalPriceAll).toFixed(2) || "0" }}
            </div>
          </div>

          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="实收总金额=订单实收金额+接单实收金额"
          placement="bottom"
          enterable
        >
            <div class="item-title">实收金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
            </el-tooltip>
            <div
              @click="
                () => {
                  collect(
                    'collectionPriceAll',
                    '实收金额',
                    [
                      { name: '订单编号/名称', type: 'input', modelKey: 'orderNoAndName' },
                      { name: '客户名称', type: 'input', modelKey: 'customerName' },
                      { name: '创建人', type: 'input', modelKey: 'createName' },
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '实收金额', colProp: ['collectionAmountAll'] },
                      { name: '创建人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ],
                    '',
                    'dis'
                  );
                }
              "
              class="item-num"
            >
              {{ parseFloat(checktitle.collectionPriceAll).toFixed(2) || "0" }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="未收总金额=订单未收金额+接单未收金额"
          placement="bottom"
          enterable
        >
            <div class="item-title">未收总金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
            </el-tooltip>
            <div
              @click="
                () => {
                  collect(
                    'unCollectionPriceAll',
                    '未收总金额',
                    [
                      { name: '订单编号/名称', type: 'input', modelKey: 'orderNoAndName' },
                      { name: '客户名称', type: 'input', modelKey: 'customerName' },
                      { name: '创建人', type: 'input', modelKey: 'createName' },
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '未收金额', colProp: ['unCollectionPriceAll'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ],
                    '',
                    'dis'
                  );
                }
              "
              class="item-num"
            >
              {{ parseFloat(checktitle.uncollectedPriceAll).toFixed(2) || "0" }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="历史欠款总金额=历史欠款订单金额+历史欠款接单金额"
          placement="bottom"
          enterable
        >
            <div class="item-title">历史欠款总金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
            </el-tooltip>
            <div
              @click="
                () => {
                  collect(
                    'historyUncollectedPriceAll',
                    '历史欠款总金额',
                    [
                      { name: '订单编号/名称', type: 'input', modelKey: 'orderNoAndName' },
                      { name: '客户名称', type: 'input', modelKey: 'customerName' },
                      { name: '订单收款方式', type: 'select', modelKey: 'paymentMethods',selOpt: [{id: 0,name: '前置收款'},{id: 1,name: '前置服务'},{id: 2,name: '免费试用'},] },
                      { name: '创建人', type: 'input', modelKey: 'createName' },
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '历史欠款总金额', colProp: ['historyUncollectedPriceAll'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ],
                    '',
                    'dis'
                  );
                }
              "
              class="item-num"
            >
              {{
                parseFloat(checktitle.historyUncollectedPriceAll).toFixed(2) || "0"
              }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="产能总金额=订单产能金额+接单产能金额"
          placement="bottom"
          enterable
        >
            <div class="item-title">产能总金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
            </el-tooltip>
            <div
              @click="
                () => {
                  collect(
                    'capacityPriceAll',
                    '产能总金额',
                    [
                      { name: '订单编号/名称', type: 'input', modelKey: 'orderNoAndName' },
                      { name: '客户名称', type: 'input', modelKey: 'customerName' },
                      { name: '创建人', type: 'input', modelKey: 'createName' },
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '产能总金额', colProp: ['capacityPriceAll'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ],
                    '',
                    'dis'
                  );
                }
              "
              class="item-num"
            >
              {{ parseFloat(checktitle.capacityPriceAll).toFixed(2) || "0" }}
            </div>
          </div>
          </div>
          <div style="display:flex;align-items: center;">
          <span class="income">订单收入</span>
          <div class="Summary-item no-width">
            <div class="item-title">订单应收金额</div>
            <div
              @click="
                () => {
                  collect(
                    'income-totalPrice',
                    '订单应收金额',
                    [
                      { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '应收金额', colProp: ['price'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ]
                  );
                }
              "
              class="item-num greenFt"
            >
              {{ parseFloat(checktitle.totalPrice).toFixed(2) || "0" }}
            </div>
          </div>

          <div class="Summary-item no-width">
            <div class="item-title">订单实收金额</div>
            <div
              @click="
                () => {
                  collect(
                    'income-collectionPrice',
                    '订单实收金额',
                    [
                      { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '实收金额', colProp: ['price'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ]
                  );
                }
              "
              class="item-num greenFt"
            >
              {{ parseFloat(checktitle.collectionPrice).toFixed(2) || "0" }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <div class="item-title">订单未收金额</div>
            <div
              @click="
                () => {
                  collect(
                    'income-unCollectionPrice',
                    '订单未收金额',
                    [
                      { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '未收金额', colProp: ['price'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ]
                  );
                }
              "
              class="item-num greenFt"
            >
              {{ parseFloat(checktitle.uncollectedPrice).toFixed(2) || "0" }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="历史欠款金额 = 非本月订单应收金额 - 非本月订单实收金额(非本月订单是指创建时间非当月的订单)"
          placement="bottom"
          enterable
        >
          <div class="item-title">
            历史欠款金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
            <div
              @click="
                () => {
                  collect(
                    'income-historyPrice',
                    '历史欠款金额',
                    [
                      { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      },
                      { name: '订单收款方式', type: 'select', modelKey: 'paymentMethods',selOpt: [{id: 0,name: '前置收款'},{id: 1,name: '前置服务'},{id: 2,name: '免费试用'},] },
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '历史欠款金额', colProp: ['price'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ]
                  );
                }
              "
              class="item-num greenFt"
            >
              {{
                parseFloat(checktitle.historyUncollectedPrice).toFixed(2) || "0"
              }}
            </div>
          </div>
          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="产能金额 = 订单总价 *（当月服务天数/总服务天数）"
          placement="bottom"
          enterable
        >
          <div class="item-title">
            产能金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
            <div
              @click="
                () => {
                  collect(
                    'income-capacityPrice',
                    '产能金额',
                    [
                      { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime', 'endTime'] },
                      { name: '应收金额', colProp: ['price'] },
                      { name: '销售负责人', colProp: ['createName'] },
                      { name: '备注', colProp: ['note'] }
                    ]
                  );
                }
              "
              class="item-num greenFt"
            >
              {{ parseFloat(checktitle.capacityPrice).toFixed(2) || "0" }}
            </div>
          </div>
          </div>
          <!-- 接单收入、、、、、、、、、、、、、、、、、、、、、 -->
          <div style="display:flex;align-items: center;">
          <span class="income">接单收入</span>
      <div class="Summary-item no-width">
        <div class="item-title">接单应收金额</div>
        <div
          @click="
            () => {
              collect(
                'disTotalPrice',
                '接单应收金额',
                [
                  { name: '订单编号/名称', type: 'input', modelKey: 'disOrderNoAndName' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '应收金额', colProp: ['disTotalPrice'] },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                    '',
                    '3'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(checktitle.disTotalPrice).toFixed(2) || "0" }}
        </div>
      </div>

      <div class="Summary-item no-width">
        <div class="item-title">接单实收金额</div>
        <div
          @click="
            () => {
              collect(
                'disCollectionPrice',
                '接单实收金额',
                [
                  { name: '订单编号/名称', type: 'input', modelKey: 'disOrderNoAndName' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '实收金额', colProp: ['disCollectionPrice'] },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                    '',
                    '3'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(checktitle.disCollectionPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <div class="item-title">接单未收金额</div>
        <div
          @click="
            () => {
              collect(
                'disUncollectedPrice',
                '接单未收金额',
                [
                  { name: '订单编号/名称', type: 'input', modelKey: 'disOrderNoAndName' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '未收金额', colProp: ['disUncollectedPrice'] },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                    '',
                    '3'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(checktitle.disUncollectedPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <el-tooltip
          class="item"
          effect="dark"
          content="历史欠款接单金额=非本月接单应收金额-非本月接单实收金额（非当月接单是指接单接收时间非当月的接单）"
          placement="bottom"
          enterable
        >
          <div class="item-title">
            历史欠款接单金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
        <div
          @click="
            () => {
              collect(
                'disHistoryUncollectedPrice',
                '历史欠款接单金额',
                [
                  { name: '订单编号/名称', type: 'input', modelKey: 'disOrderNoAndName' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '订单收款方式', type: 'select', modelKey: 'paymentMethods',selOpt: [{id: 0,name: '前置收款'},{id: 1,name: '前置服务'},{id: 2,name: '免费试用'},] },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '历史欠款接单金额', colProp: ['disHistoryUncollectedPrice'] },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                    '',
                    '3'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(checktitle.disHistoryUncollectedPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <el-tooltip
          class="item"
          effect="dark"
          content="接单产能金额=结算金额 *【（当月店铺a服务天数/店铺总服务天数）+（当月店铺b服务天数/店铺总服务天数）】+..."
          placement="bottom"
          enterable
        >
          <div class="item-title">
            接单产能金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
        <div
          @click="
            () => {
              collect(
                'disCapacityPrice',
                '接单产能金额',
                [
                  { name: '订单编号/名称', type: 'input', modelKey: 'disOrderNoAndName' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '接单产能金额', colProp: ['disCapacityPrice'] },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                    '',
                    '3'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(checktitle.disCapacityPrice).toFixed(2) || "0" }}
        </div>
      </div>
          </div>
          <div style="display:flex;align-items: center;">
          <span class="income">盈利</span>
          <div class="Summary-item no-width">
            <el-tooltip
          class="item"
          effect="dark"
          content="盈利=收入-支出"
          placement="bottom"
          enterable
        >
            <div class="item-title">公司盈利<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
            </el-tooltip>
            <div class="item-num greyFt">
              {{ parseFloat(checktitle.profit).toFixed(2) || "0" }}
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <div class="common-isMore-btn" @click="isMore = !isMore">{{isMore ? '收起' : '查看更多'}}<i v-if="!isMore" class="el-icon-caret-bottom"></i><i v-if="isMore" class="el-icon-caret-top"></i></div>

  <div class="common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <!-- <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          @change="commonFun(true)"
          v-model="params.companyId"
          placeholder="请选择公司"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="common-input-container">
        <span>流水编号:</span>
        <el-input
          v-model="params.code"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>类型:</span>
        <el-select
          v-model="params.flowType"
          placeholder="请选择"
          class="common-screen-input"
          clearable
          @change="commonFun"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>收支方式:</span>
        <el-select
          v-model="params.recordType"
          placeholder="请选择"
          class="common-screen-input"
          clearable
          @change="commonFun"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>创建人:</span>
        <el-input
          v-model="params.createName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            commonFun();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <!-- biaoge -->
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="code"
        label="流水编号"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="amount" label="金额" :formatter="tableColumn">
        <template slot-scope="scope">
          <span v-if="scope.row.flowType == 'income'" style="color: #F09009"
            >+{{ scope.row.amount }}</span
          >
          <span v-if="scope.row.flowType == 'expenditure'"
            >-{{ scope.row.amount }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="flowType" label="类型" :formatter="tableColumn">
        <template slot-scope="scope">
          <span>{{ scope.row.flowType == "income" ? "收入" : "支出" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="recordType"
        label="收支方式"
        :formatter="tableColumn"
      >
        <template slot-scope="scope">
          <!-- <span>{{
            scope.row.recordType == "bankCard"
              ? "银行卡"
              : scope.row.recordType == "alipay"
              ? "支付宝"
              : scope.row.recordType == "wechat"
              ? "微信"
              : scope.row.recordType == "cash"
              ? "现金"
              : scope.row.recordType == "corporateAccount"
                        ? "对公账户"
                        : "其他"
          }}</span> -->
          <span v-if="scope.row.recordType == 'bankCard'">银行卡</span>
          <span v-else-if="scope.row.recordType == 'alipay'">支付宝</span>
          <span v-else-if="scope.row.recordType == 'wechat'">微信</span>
          <span v-else-if="scope.row.recordType == 'cash'">现金</span>
          <span v-else-if="scope.row.recordType == 'corporateAccount'"
            >对公账户</span
          >
          <span v-else-if="scope.row.recordType == 'generalAccount'"
            >对公账户（总）</span
          >
          <span v-else-if="scope.row.recordType == 'publicAccount'"
            >对公账户（分）</span
          >
          <span v-else-if="scope.row.recordType == 'taobao'">淘宝</span>
          <span v-else-if="scope.row.recordType == 'tiktok'">抖音</span>
          <span v-else-if="scope.row.recordType == 'pinduoduo'">拼多多</span>
          <span v-else-if="scope.row.recordType == 'other'">其他</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmRecordTime" label="收支时间">
      </el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>

    <!-- 新增弹框 -->
    <el-dialog title="提示" :visible.sync="newAddVisible" width="30%">
      <el-form ref="form" :model="seeMoreForm">
        <el-form-item label="订单总额及应收金额">
          <el-input v-model="seeMoreForm.name"></el-input>
        </el-form-item>
        <el-form-item label="填写实收金额">
          <el-input v-model="seeMoreForm.name"></el-input>
        </el-form-item>
        <el-form-item label="选择收款方式">
          <el-select v-model="seeMoreForm.mode" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通知人">
          <el-input v-model="seeMoreForm.name"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="seeMoreForm.name"></el-input>
        </el-form-item>
        <el-form-item label="添加图片及文件">
          <el-input v-model="seeMoreForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newAddVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            () => {
              sendRequest();
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="report"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
    <!-- 总收入头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog2"
      :methodFuc="getFinancialReportList2"
      :commonSumVisible="commonSumVisible2"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
    <!-- 接单头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog3"
      :methodFuc="getFinancialReportList"
      :commonSumVisible="commonSumVisible3"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn, Config } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate";
import { checkTitle, checkList,getFinancialReportList2,getFinancialReportList } from "../../service/money";
import CommonSumDialog from "../../components/common/commonSumDialog";
import { report } from "../../service/home.js";
import { mapState } from "vuex";

export default {
  name: "Check",
  components: {
    Breadcrumb,
    CommonDate,
    CommonSumDialog
  },
  props: {},
  data() {
    return {
      tableColumn,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "财务", isLink: false },
        { title: "流水列表", isLink: false }
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10
      },
      dialogVisible: false,
      newAddVisible: false, //新增弹框
      seeMoreForm: {
        name: "",
        mode: "" //收款方式
      },
      tableData: [],
      options: [
        {
          value: "expenditure",
          label: "支出"
        },
        {
          value: "income",
          label: "收入"
        }
      ],
      options2: [
        {
          value: "generalAccount",
          label: "对公账户（总）"
        },
        {
          value: "publicAccount",
          label: "对公账户（分）"
        },
        {
          value: "taobao",
          label: "淘宝"
        },
        {
          value: "tiktok",
          label: "抖音"
        },
        {
          value: "pinduoduo",
          label: "拼多多"
        },
        {
          value: "bankCard",
          label: "银行卡"
        },
        {
          value: "alipay",
          label: "支付宝"
        },
        {
          value: "wechat",
          label: "微信"
        },
        {
          value: "cash",
          label: "现金"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      options3: [
        {
          value: "wages",
          label: "工资"
        },
        {
          value: "reimbursement",
          label: "报销"
        },
        {
          value: "supplierSettlement",
          label: "供应商结算"
        },
        {
          value: "refund",
          label: "退款"
        },
        {
          value: "marketingManagement",
          label: "市场营销"
        },
        {
          value: "fine",
          label: "罚款"
        },
        {
          value: "public relationsExpenses",
          label: "公关费"
        },
        {
          value: "rent",
          label: "房租水电成本"
        },
        {
          value: "introduction",
          label: "介绍费"
        },
        {
          value: "tax",
          label: "税费"
        },
        {
          value: "socialSecurity",
          label: "社保费"
        },
        {
          value: "development",
          label: "研发费用"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      loading: false,
      loading2: false,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company,
      checktitle: {},
      // 汇总弹窗所需数据
      commonSumVisible: false,
      commonSumVisible2: false,
      commonSumVisible3: false,
      report, // 列表接口方法
      getFinancialReportList2,// 列表接口方法2
      getFinancialReportList,// 列表接口方法3
      lineNum: "",
      isMore: false, // 查看更多
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getCheckTitle();
        this.getCheckList();
      },400)
    },
    // 数据汇总弹窗start
    sumVisibleClose() {
      if(this.lineNum == 2){
        this.commonSumVisible2 = false;
      }else if(this.lineNum == '3'){
        this.commonSumVisible3 = false;
      }else{
        this.commonSumVisible = false;
      }
    },
    collect(type, name, screen, arr, mode,val) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      if(val){
        if(val == '3'){
          this.lineNum = 3
        this.$refs.commonSumDialog3.getAllData(
          type,
          name,
          screen,
          arr,
          this.params.companyId
        );
        this.commonSumVisible3 = true
        }else{
          this.lineNum = 2
        this.$refs.commonSumDialog2.getAllData(
          type,
          name,
          screen,
          arr,
          this.params.companyId
        );
        this.commonSumVisible2 = true
        }
      }else{
        this.lineNum = 1
        if (mode == "payout") {
        this.$refs.commonSumDialog.getAllData(
          type,
          name,
          screen,
          arr,
          this.params.companyId,
          "",
          this.options3,
          this.options2
        );
      } else {
        this.$refs.commonSumDialog.getAllData(
          type,
          name,
          screen,
          arr,
          this.params.companyId
        );
      }
        this.commonSumVisible = true;
      }
    },
    // 数据汇总弹窗end
    commonGetDate(startTime, endTime) {
      this.params.startDate = startTime;
      this.params.endDate = endTime;
      this.getCheckList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getCheckList();
    },
    commonFun(flag) {
      this.params.pageSize = 10;
      this.params.page = 1;
      this.getCheckList();
      if (flag == true) {
        this.getCheckTitle();
      }
    },
    seeMore() {
      this.dialogVisible = true;
    },
    // 新增按钮
    newAddEdit() {
      this.newAddVisible = true;
    },
    // 确认按钮发请求
    sendRequest() {
      this.newAddVisible = false;
    },
    // 删除按钮
    async deleteChance(id) {
      console.log(id);
      this.$message.success("删除成功");
    },
    resetBtn() {
      (this.params = {
        page: 1,
        pageSize: 10,
        companyId: this.comId
      }),
        this.$refs.commonReset.resetFun();
      this.getCheckList();
    },
    async getCheckList() {
      this.loading = true;
      let params = { ...this.params };
      const { data } = await checkList(params);
      this.tableData = data.list;
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.loading = false;
    },
    async getCheckTitle() {
      this.loading2 = true
      let id = this.params.companyId;
      const { data } = await checkTitle({ companyId: id });
      this.checktitle = data;
      this.loading2 = false
    },
    download() {
      let params = { ...this.params };
      delete params.page;
      delete params.pageSize;
      let url = Config.api.url + "/financial/financialFlow/getReconciliationListExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");

      window.location.href = url;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getComId()

    // this.getCheckTitle();
    // this.getCheckList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.income {
  text-align: left;
  width: 120px;
  font-size: 28px;
  color: #101010;
  margin: 0 48px;
}
// .Summary-item {
//   width: 200px !important;
// }
.common-input-container1 {
  width: 100px;
  display: inline-block;
  margin-right: 16px;
}
.no-width {
  width: 17%;
}
.greenFt{
  color: #52C419 !important;
}
.orangeFt{
  color: #EE8E08 !important;
}
.redFt{
  color: #FF4D4F !important;
}
.greyFt{
  color: #333 !important;
  cursor: default !important;
}
.isMore-height {
    transition: height 0.5s;
    overflow: hidden;
    height: 390px;
  }
  .isMore-height0 {
    transition: height 0.5s;
    overflow: hidden;
    height: 0;
    padding: 0 !important;
    margin: 0 !important;
  }
    .tit-img {
  margin-left: 3px;
  margin-top: 2px;
  cursor: pointer;
}
</style>
